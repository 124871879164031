var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workPlatForm"},[_c('div',{staticClass:"operation"},[_c('div',{staticClass:"title"},[_vm._v("运营概况")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"item item1"},[_vm._m(0),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"h3"},[_vm._v("当前用户总量")]),_c('div',{staticClass:"h4"},[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.operationData.userCount ? _vm.operationData.userCount : 0,"duration":3000}}),_vm._v("位 ")],1)])]),_c('div',{staticClass:"item item2"},[_vm._m(1),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"h3"},[_vm._v("今日访问量")]),_c('div',{staticClass:"h4"},[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.operationData.todayVisit ? _vm.operationData.todayVisit : 0,"duration":3000}}),_vm._v("人次 ")],1)])]),_c('div',{staticClass:"item item3"},[_vm._m(2),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"h3"},[_vm._v("访问总量")]),_c('div',{staticClass:"h4"},[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.operationData.visitCount ? _vm.operationData.visitCount : 0,"duration":3000}}),_vm._v("人次 ")],1)])]),_c('div',{staticClass:"item item4"},[_vm._m(3),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"h3"},[_vm._v("累计产生积分")]),_c('div',{staticClass:"h4"},[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.operationData.producePointCount
                  ? _vm.operationData.producePointCount
                  : 0,"duration":3000}}),_vm._v("分 ")],1)])]),_c('div',{staticClass:"item item5"},[_vm._m(4),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"h3"},[_vm._v("累计核销积分")]),_c('div',{staticClass:"h4"},[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.operationData.consumePointCount
                  ? _vm.operationData.consumePointCount
                  : 0,"duration":3000}}),_vm._v("分 ")],1)])])])]),_c('div',{staticClass:"activity"},[_c('div',{staticClass:"title",on:{"click":_vm.toActivity}},[_vm._v(" 活动概览"),_c('img',{attrs:{"src":require("./img/enter.png"),"alt":""}})]),_vm._m(5)]),_c('div',{staticClass:"topic"},[_c('div',{staticClass:"title",on:{"click":_vm.toTopicList}},[_vm._v(" 超话概览"),_c('img',{attrs:{"src":require("./img/enter.png"),"alt":""}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"main-t"},[_c('div',{staticClass:"item"},[_vm._m(6),_c('div',{staticClass:"item-r"},[_c('h3',[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.topicPraiseAndCommentCount.praiseCount
                    ? _vm.topicPraiseAndCommentCount.praiseCount
                    : 0,"duration":3000}})],1),_c('h3',[_vm._v("点赞数量（条）")])])]),_c('div',{staticClass:"block"}),_c('div',{staticClass:"item"},[_vm._m(7),_c('div',{staticClass:"item-r"},[_c('h3',[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.topicPraiseAndCommentCount.commentCount
                    ? _vm.topicPraiseAndCommentCount.commentCount
                    : 0,"duration":3000}})],1),_c('h3',[_vm._v("评论数量（条）")])])]),_c('div',{staticClass:"block"}),_c('div',{staticClass:"item"},[_vm._m(8),_c('div',{staticClass:"item-r"},[_c('h3',[_c('countTo',{attrs:{"startVal":0,"endVal":_vm.topicPraiseAndCommentCount.pointCount
                    ? _vm.topicPraiseAndCommentCount.pointCount
                    : 0,"duration":3000}})],1),_c('h3',[_vm._v("产生积分数量")])])])]),_vm._m(9)])]),_c('div',{staticClass:"point"},[_c('div',{staticClass:"title",on:{"click":_vm.toPoint}},[_vm._v(" 积分概览"),_c('img',{attrs:{"src":require("./img/enter.png"),"alt":""}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"main-l"},[_c('div',{staticClass:"select"},[_c('v-select',{attrs:{"options":_vm.pointsCountArr},on:{"change":_vm.changeDate},model:{value:(_vm.pointsCountData.type),callback:function ($$v) {_vm.$set(_vm.pointsCountData, "type", $$v)},expression:"pointsCountData.type"}})],1),_c('div',{attrs:{"id":"point"}})]),_vm._m(10)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/user.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/totayNum.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/total.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/point.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/hexiao.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main-l"},[_c('div',{attrs:{"id":"talent"}})]),_c('div',{staticClass:"main-r"},[_c('div',{attrs:{"id":"activityNum"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/zan.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/commentNum.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/pointNum.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-b"},[_c('div',{attrs:{"id":"topicBox"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-r"},[_c('div',{attrs:{"id":"pointCast"}})])
}]

export { render, staticRenderFns }