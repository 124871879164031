import * as echarts from "echarts";
//活动概况
export function drawActivityChart(eleId, xData, seriesData) {
  let option = {
    tooltip: {
      trigger: "axis",
    },
    toolbox: {},
    xAxis: [
      {
        type: "category",
        data: xData,
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "",
        interval: 50,

        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        interval: 5,
        axisLabel: {
          formatter: "{value} ",
        },
        lineStyle: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: "活动数量",
        type: "bar",
        tooltip: {
          valueFormatter: function (value) {
            return value;
          },
        },
        itemStyle: {
          color: "rgba(79, 135, 236, 1)",
        },
        data: seriesData.barData,
      },
      {
        name: "活动人次",
        type: "line",
        yAxisIndex: 1,
        itemStyle: {
          color: "rgba(100, 185, 194, 1)",
        },
        tooltip: {
          valueFormatter: function (value) {
            return value;
          },
        },
        data: seriesData.lineData,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

/// 达人概况
export function drawTalentChart(eleId, xData, seriesData, name) {
  let option = {
    tooltip: {
      trigger: "item",
    },
    title: [
      {
        text: name,
        left: "center",
        top: "40%",
      },
      {
        text: xData + "人",
        left: "center",
        top: "50%",
      },
    ],
    series: [
      {
        name: "",
        type: "pie",
        radius: ["40%", "70%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        data: seriesData,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
/// 条形图
export function drawColumnChart(eleId, xData, seriesData) {
  let option = {
    xAxis: {
      max: "dataMax",
    },
    yAxis: {
      type: "category",
      data: xData,
      inverse: true,
      axisLabel: {},
    },
    grid: { top: "10%", left: "26%", right: "10%", bottom: "10%" },
    series: [
      {
        type: "bar",
        data: seriesData,
        label: {
          show: true,
          position: "right",
          valueAnimation: true,
        },
        itemStyle: {
          normal: {
            color: "rgba(47, 124, 255, .5)",
          },
        },
      },
    ],
    legend: {
      show: true,
    },
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
/// 水波图
export function drawLineChart(eleId, xData, seriesData1, seriesData2) {
  let option = {
    xAxis: {
      type: "category",
      data: xData,
      splitLine: {
        show: false,
        lineStyle: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      axisLabel: {
        interval: 0,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    grid: { top: "10%", left: "10%", right: "10%", bottom: "20%" },
    series: [
      {
        name: "核销积分",
        data: seriesData1,
        type: "line",
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          color: "rgba(104, 187, 196, 1)",
        },
        areaStyle: {
          color: "rgba(226, 241, 243, 1)",
        },
        itemStyle: {
          normal: {
            color: "rgba(104, 187, 196, 1)",
          },
        },
        tooltip: {
          valueFormatter: function (value) {
            return value;
          },
        },
      },
      {
        name: "产生积分",
        data: seriesData2,
        type: "line",
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          color: "rgba(80, 136, 236, 1)",
        },
        areaStyle: {
          color: "rgba(221, 231, 252, 1)",
        },
        itemStyle: {
          normal: {
            color: "rgba(80, 136, 236, 1)",
          },
        },
        tooltip: {
          valueFormatter: function (value) {
            return value;
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
/// 饼图
export function drawPieChart(eleId, xData, seriesData) {
  let option = {
    title: {
      text: "",
      subtext: "",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      right: "right",
      top: "middle",
    },
    series: [
      {
        name: "资源调用每日次数接口分布图",
        type: "pie",
        radius: "50%",
        center: ["30%", "50%"],
        data: seriesData,
        label: {
          normal: {
            show: true,
            formatter: "{d}%",
          },
        },
        emphasis: {
          center: ["40%", "50%"],
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 双折线
export function drawDLineChart(eleId, xData, seriesData1, seriesData2) {
  let option = {
    xAxis: {
      type: "category",
      data: xData,
      axisLabel: {
        interval: 0,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    tooltip: {
      trigger: "axis",
      // formatter: "{b0}<br/>社区超话: {c1}<br />居民超话: {c0}",
    },
    grid: { top: "10%", left: "14%", right: "10%", bottom: "20%" },
    series: [
      {
        name: "居民超话",
        data: seriesData1,
        type: "line",
        symbolSize: 0,
        lineStyle: {
          color: "rgba(100, 185, 194, 1)",
        },
        tooltip: {
          valueFormatter: function (value) {
            return value;
          },
        },
      },
      {
        name: "社区超话",
        data: seriesData2,
        type: "line",
        symbolSize: 0,
        lineStyle: {
          color: "rgba(79, 135, 236, 1)",
        },
        tooltip: {
          valueFormatter: function (value) {
            return value;
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param); //这里根据param填写你的跳转逻辑
  });
}
// 绘制图表
function drawedChart(eleId, option) {
  let chart = null;
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId));
    chart.setOption(option);
  }
  return chart;
}
