import { mapHelper } from "@/utils/common.js";

const pointsCountArr = [
  {
    value: 1,
    label: "年",
  },
  {
    value: 3,
    label: "周",
  },
];

const { map: pointsCountArrMap, setOps: pointsCountArrOps } =
  mapHelper.setMap(pointsCountArr);

export { pointsCountArr, pointsCountArrOps };
