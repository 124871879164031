<template>
  <div class="workPlatForm">
    <div class="operation">
      <div class="title">运营概况</div>
      <div class="main">
        <div class="item item1">
          <div class="item-l"><img src="./img/user.png" alt="" /></div>
          <div class="item-r">
            <div class="h3">当前用户总量</div>
            <div class="h4">
              <countTo
                :startVal="0"
                :endVal="operationData.userCount ? operationData.userCount : 0"
                :duration="3000"
              ></countTo
              >位
            </div>
          </div>
        </div>
        <div class="item item2">
          <div class="item-l"><img src="./img/totayNum.png" alt="" /></div>
          <div class="item-r">
            <div class="h3">今日访问量</div>
            <div class="h4">
              <countTo
                :startVal="0"
                :endVal="
                  operationData.todayVisit ? operationData.todayVisit : 0
                "
                :duration="3000"
              ></countTo
              >人次
            </div>
          </div>
        </div>
        <div class="item item3">
          <div class="item-l"><img src="./img/total.png" alt="" /></div>
          <div class="item-r">
            <div class="h3">访问总量</div>
            <div class="h4">
              <countTo
                :startVal="0"
                :endVal="
                  operationData.visitCount ? operationData.visitCount : 0
                "
                :duration="3000"
              ></countTo
              >人次
            </div>
          </div>
        </div>
        <div class="item item4">
          <div class="item-l"><img src="./img/point.png" alt="" /></div>
          <div class="item-r">
            <div class="h3">累计产生积分</div>
            <div class="h4">
              <countTo
                :startVal="0"
                :endVal="
                  operationData.producePointCount
                    ? operationData.producePointCount
                    : 0
                "
                :duration="3000"
              ></countTo
              >分
            </div>
          </div>
        </div>
        <div class="item item5">
          <div class="item-l"><img src="./img/hexiao.png" alt="" /></div>
          <div class="item-r">
            <div class="h3">累计核销积分</div>
            <div class="h4">
              <countTo
                :startVal="0"
                :endVal="
                  operationData.consumePointCount
                    ? operationData.consumePointCount
                    : 0
                "
                :duration="3000"
              ></countTo
              >分
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="activity">
      <div class="title" @click="toActivity">
        活动概览<img src="./img/enter.png" alt="" />
      </div>
      <div class="main">
        <div class="main-l">
          <div id="talent"></div>
        </div>
        <div class="main-r">
          <div id="activityNum"></div>
        </div>
      </div>
    </div>
    <div class="topic">
      <div class="title" @click="toTopicList">
        超话概览<img src="./img/enter.png" alt="" />
      </div>
      <div class="main">
        <div class="main-t">
          <div class="item">
            <div class="item-l"><img src="./img/zan.png" alt="" /></div>
            <div class="item-r">
              <h3>
                <countTo
                  :startVal="0"
                  :endVal="
                    topicPraiseAndCommentCount.praiseCount
                      ? topicPraiseAndCommentCount.praiseCount
                      : 0
                  "
                  :duration="3000"
                ></countTo>
              </h3>
              <h3>点赞数量（条）</h3>
            </div>
          </div>
          <div class="block"></div>
          <div class="item">
            <div class="item-l"><img src="./img/commentNum.png" alt="" /></div>
            <div class="item-r">
              <h3>
                <countTo
                  :startVal="0"
                  :endVal="
                    topicPraiseAndCommentCount.commentCount
                      ? topicPraiseAndCommentCount.commentCount
                      : 0
                  "
                  :duration="3000"
                ></countTo>
              </h3>
              <h3>评论数量（条）</h3>
            </div>
          </div>
          <div class="block"></div>
          <div class="item">
            <div class="item-l"><img src="./img/pointNum.png" alt="" /></div>
            <div class="item-r">
              <h3>
                <countTo
                  :startVal="0"
                  :endVal="
                    topicPraiseAndCommentCount.pointCount
                      ? topicPraiseAndCommentCount.pointCount
                      : 0
                  "
                  :duration="3000"
                ></countTo>
              </h3>
              <h3>产生积分数量</h3>
            </div>
          </div>
        </div>
        <div class="main-b">
          <div id="topicBox"></div>
        </div>
      </div>
    </div>
    <div class="point">
      <div class="title" @click="toPoint">
        积分概览<img src="./img/enter.png" alt="" />
      </div>
      <div class="main">
        <div class="main-l">
          <div class="select">
            <v-select
              :options="pointsCountArr"
              v-model="pointsCountData.type"
              @change="changeDate"
            />
          </div>
          <div id="point"></div>
        </div>
        <div class="main-r">
          <div id="pointCast"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  drawActivityChart,
  drawTalentChart,
  drawDLineChart,
  drawLineChart,
} from "./echart";
import { pointsCountArr } from "./map";
import { debounce } from "@/utils/utils";
import {
  getTalentCountUrl,
  getOperationOverviewUrl,
  getActivityCountUrl,
  getSuperTopicPraiseAndCommentCountUrl,
  getSuperTopicCountUrl,
  getPointsCountUrl,
  getPointsMallExcCountUrl,
} from "./api";
import countTo from "vue-count-to";
export default {
  name: "workPlatForm",
  data() {
    return {
      pointsCountArr,
      operationData: {
        consumePointCount: 0,
        producePointCount: 0,
        visitCount: 0,
        todayVisit: 0,
        userCount: 0,
      },
      topicPraiseAndCommentCount: {
        praiseCount: 0,
        commentCount: 0,
        pointCount: 0,
      },
      judgeActivity: "",
      judgeTalent: "",
      judgeTopic: "",
      judgePoint: "",
      judgePointCast: "",
      pointsMallExcCountData: { x: 0, y: [], pieData: [] },
      talentData: {
        x: 0,
        y: [],
        pieData: [],
      },
      activityData: {
        x: [],
        y: [],
        barData: [],
        lineData: [],
      },
      superTopicCountData: {
        x: [],
        y1: [],
        y2: [],
      },
      pointsCountData: {
        x: [],
        y1: [],
        y2: [],
        type: 1,
      },
    };
  },
  components: {
    countTo,
  },
  created() {},
  async mounted() {
    let _this = this;
    this.getOperationOverview();
    this.getSuperTopicPraiseAndCommentCount();
    let oTalentCount = await this.getTalentCount();
    if (oTalentCount.code === 200) {
      oTalentCount.data.forEach((ele) => {
        if (ele.talentCount) {
          this.talentData.x = this.talentData.x + Number(ele.talentCount);
        } else {
          this.talentData.x = this.talentData.x + 0;
        }
        let obj = {};
        obj.value = ele.talentCount;
        obj.name = ele.talentName;
        this.talentData.pieData.unshift(obj);
      });
      drawTalentChart(
        "talent",
        this.talentData.x,
        this.talentData.pieData,
        "社区达人总数"
      );
      this.judgeTalent = debounce(() => {
        drawTalentChart(
          "talent",
          this.talentData.x,
          this.talentData.pieData,
          "社区达人总数"
        ).resize();
      }, 100);
    }
    let res = await this.getActivityCount();
    if (res.code == 200) {
      res.data.forEach((ele) => {
        this.activityData.x.push(ele.month);
        this.activityData.barData.push(ele.activityCount);
        this.activityData.lineData.push(ele.joinCount);
      });
      drawActivityChart("activityNum", this.activityData.x, this.activityData);
      this.judgeActivity = debounce(() => {
        drawActivityChart(
          "activityNum",
          this.activityData.x,
          this.activityData
        ).resize();
      }, 100);
    }
    let oRes = await this.getSuperTopicCount();
    if (oRes.code == 200) {
      oRes.data.forEach((ele) => {
        this.superTopicCountData.x.push(ele.month);
        this.superTopicCountData.y1.push(ele.communityCount);
        this.superTopicCountData.y2.push(ele.residentCount);
      });
      drawDLineChart(
        "topicBox",
        this.superTopicCountData.x,
        this.superTopicCountData.y1,
        this.superTopicCountData.y2
      );
      this.judgeTopic = debounce(() => {
        drawDLineChart(
          "topicBox",
          _this.superTopicCountData.x,
          _this.superTopicCountData.y1,
          _this.superTopicCountData.y2
        ).resize();
      }, 100);
    }
    let oPointsCount = await this.getPointsCount();
    if (oPointsCount.code == 200) {
      oPointsCount.data.forEach((ele) => {
        this.pointsCountData.x.push(ele.day);
        this.pointsCountData.y1.push(ele.consumeCount);
        this.pointsCountData.y2.push(ele.produceCount);
      });
      drawLineChart(
        "point",
        this.pointsCountData.x,
        this.pointsCountData.y1,
        this.pointsCountData.y2
      );
      this.judgePoint = debounce(() => {
        drawLineChart(
          "point",
          this.pointsCountData.x,
          this.pointsCountData.y1,
          this.pointsCountData.y2
        ).resize();
      }, 100);
    }
    let oPointsMallExcCount = await this.getPointsMallExcCount();
    if (oPointsMallExcCount.code == 200) {
      oPointsMallExcCount.data.forEach((ele) => {
        if (ele.countSum) {
          this.pointsMallExcCountData.x =
            this.pointsMallExcCountData.x + Number(ele.countSum);
        } else {
          this.pointsMallExcCountData.x = this.pointsMallExcCountData.x + 0;
        }
        let obj = {};
        obj.value = ele.countSum;
        obj.name = ele.goodsName;
        this.pointsMallExcCountData.pieData.unshift(obj);
      });
      drawTalentChart(
        "pointCast",
        this.pointsMallExcCountData.x,
        this.pointsMallExcCountData.pieData,
        "积分商城兑换量"
      );
      this.judgePointCast = debounce(() => {
        drawTalentChart(
          "pointCast",
          this.pointsMallExcCountData.x,
          this.pointsMallExcCountData.pieData,
          "积分商城兑换量"
        ).resize();
      }, 100);
    }
    window.addEventListener("resize", _this.judgeActivity, true);
    window.addEventListener("resize", _this.judgeTalent, true);
    window.addEventListener("resize", _this.judgeTopic, true);
    window.addEventListener("resize", _this.judgePoint, true);
    window.addEventListener("resize", _this.judgePointCast, true);
  },
  destroyed() {
    let _this = this;
    window.removeEventListener("resize", _this.judgeActivity);
    window.removeEventListener("resize", _this.judgeTalent);
    window.removeEventListener("resize", _this.judgeTopic);
    window.removeEventListener("resize", _this.judgePoint);
    window.removeEventListener("resize", _this.judgePointCast);
  },
  methods: {
    toActivity() {
      this.$router.push({
        name: "applyActivity",
      });
    },
    toTopicList() {
      this.$router.push({
        name: "topicList",
      });
    },
    toPoint() {
      this.$router.push({
        name: "goodsList",
      });
    },
    async changeDate() {
      this.pointsCountData.x = [];
      this.pointsCountData.y1 = [];
      this.pointsCountData.y2 = [];
      let res = await this.getPointsCount();
      if (res.code == 200) {
        res.data.forEach((ele) => {
          this.pointsCountData.x.push(ele.day);
          this.pointsCountData.y1.push(ele.consumeCount);
          this.pointsCountData.y2.push(ele.produceCount);
        });
        this.judgePoint();
      }
    },
    async getPointsMallExcCount() {
      let params = {};
      let res = await this.$axios.get(`${getPointsMallExcCountUrl}`);
      return res;
    },
    async getPointsCount() {
      let params = {
        type: this.pointsCountData.type,
      };
      let res = await this.$axios.get(`${getPointsCountUrl}`, { params });
      return res;
    },
    async getSuperTopicCount() {
      let params = {};
      let res = await this.$axios.get(`${getSuperTopicCountUrl}`);
      return res;
    },
    async getSuperTopicPraiseAndCommentCount() {
      let params = {};
      let res = await this.$axios.get(
        `${getSuperTopicPraiseAndCommentCountUrl}`
      );
      if (res.code === 200) {
        this.topicPraiseAndCommentCount = res.data;
      }
    },
    async getOperationOverview() {
      let params = {};
      let res = await this.$axios.get(`${getOperationOverviewUrl}`);
      if (res.code === 200) {
        this.operationData = res.data;
      }
    },
    async getActivityCount() {
      let params = {};
      let res = await this.$axios.get(`${getActivityCountUrl}`, { params });
      return res;
    },
    async getTalentCount() {
      let params = {};
      let res = await this.$axios.get(`${getTalentCountUrl}`, { params });
      return res;
    },
  },
};
</script>
<style lang="less" scoped>
.workPlatForm {
  box-sizing: border-box;
  height: 100%;
  padding: 40px 20px;
  overflow: auto;
  .title {
    font-size: 20px;
    cursor: pointer;
    font-weight: 700;
    margin: 20px 0 40px;
    position: relative;
    img {
      position: absolute;
      width: 45px;
      height: 20px;
      top: 0;
      bottom: 0;
      right: 10px;
    }
  }
  .point {
    .main {
      display: flex;
      height: 400px;
      .main-l {
        width: 60%;
        height: 100%;
        .select {
          display: flex;
          justify-content: right;
        }
        #point {
          height: 100%;
        }
      }
      .main-r {
        width: 30%;
        height: 100%;
        #pointCast {
          height: 100%;
        }
      }
    }
  }
  .topic {
    .main {
      .main-t {
        display: flex;
        justify-content: space-around;
        .block {
          width: 2px;
          height: 60px;
          background: #eee;
        }
        .item {
          display: flex;
          align-items: center;
          .item-l {
            width: 60px;
            height: 60px;
            margin-right: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-r {
            h3 {
              text-align: center;
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 10px;
            }
          }
        }
      }
      .main-b {
        height: 400px;
        #topicBox {
          height: 100%;
        }
      }
    }
  }
  .activity {
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 400px;
    }
    .main-l {
      width: 30%;
      height: 100%;
      #talent {
        height: 100%;
      }
    }
    .main-r {
      width: 60%;
      height: 100%;
      #activityNum {
        height: 100%;
      }
    }
  }
  .operation {
    .main {
      display: flex;

      .item {
        flex: 1;
        padding: 30px 15px;
        margin-right: 10px;
        display: flex;
        border-radius: 8px;
        justify-content: space-around;
        align-items: center;
        .item-l {
          margin-left: 20px;
          width: 90px;
          height: 90px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-r {
          .h3 {
            color: #fff;
            font-size: 20px;
            margin-bottom: 10px;
          }
          .h4 {
            color: #fff;
            font-size: 26px;
            font-weight: 700;
          }
        }
      }
      .item1 {
        background: rgba(255, 121, 112, 1);
      }
      .item2 {
        background: rgba(255, 211, 68, 1);
      }
      .item3 {
        background: rgba(100, 140, 255, 1);
      }
      .item4 {
        background: rgba(78, 204, 176, 1);
      }
      .item5 {
        background: rgba(120, 40, 245, 1);
      }
    }
  }
}
</style>
