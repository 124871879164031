//达人情况
const getTalentCountUrl = `/gateway/hc-low-carbon/workbench/getTalentCount`;
//运营情况
const getOperationOverviewUrl = `/gateway/hc-low-carbon/workbench/getOperationOverview`;
//活动概况
const getActivityCountUrl = `/gateway/hc-low-carbon/workbench/getActivityCount`;
//超话概况
const getSuperTopicCountUrl = `/gateway/hc-low-carbon/workbench/getSuperTopicCount`;
//超话点赞概况
const getSuperTopicPraiseAndCommentCountUrl = `/gateway/hc-low-carbon/workbench/getSuperTopicPraiseAndCommentCount`;
// 积分概览
const getPointsCountUrl = `/gateway/hc-low-carbon/workbench/getPointsCount`;
// 积分商城兑换量
const getPointsMallExcCountUrl = `/gateway/hc-low-carbon/workbench/getPointsMallExcCount`;
export {
  getSuperTopicPraiseAndCommentCountUrl,
  getTalentCountUrl,
  getOperationOverviewUrl,
  getSuperTopicCountUrl,
  getActivityCountUrl,
  getPointsCountUrl,
  getPointsMallExcCountUrl,
};
